// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ja-blog-js": () => import("./../../../src/pages/ja/blog.js" /* webpackChunkName: "component---src-pages-ja-blog-js" */),
  "component---src-pages-ja-contact-js": () => import("./../../../src/pages/ja/contact.js" /* webpackChunkName: "component---src-pages-ja-contact-js" */),
  "component---src-pages-ja-index-js": () => import("./../../../src/pages/ja/index.js" /* webpackChunkName: "component---src-pages-ja-index-js" */),
  "component---src-pages-ja-services-js": () => import("./../../../src/pages/ja/services.js" /* webpackChunkName: "component---src-pages-ja-services-js" */),
  "component---src-pages-ja-submitted-js": () => import("./../../../src/pages/ja/submitted.js" /* webpackChunkName: "component---src-pages-ja-submitted-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-submitted-js": () => import("./../../../src/pages/submitted.js" /* webpackChunkName: "component---src-pages-submitted-js" */),
  "component---src-pages-vi-blog-js": () => import("./../../../src/pages/vi/blog.js" /* webpackChunkName: "component---src-pages-vi-blog-js" */),
  "component---src-pages-vi-contact-js": () => import("./../../../src/pages/vi/contact.js" /* webpackChunkName: "component---src-pages-vi-contact-js" */),
  "component---src-pages-vi-index-js": () => import("./../../../src/pages/vi/index.js" /* webpackChunkName: "component---src-pages-vi-index-js" */),
  "component---src-pages-vi-services-js": () => import("./../../../src/pages/vi/services.js" /* webpackChunkName: "component---src-pages-vi-services-js" */),
  "component---src-pages-vi-submitted-js": () => import("./../../../src/pages/vi/submitted.js" /* webpackChunkName: "component---src-pages-vi-submitted-js" */),
  "component---src-pages-zh-blog-js": () => import("./../../../src/pages/zh/blog.js" /* webpackChunkName: "component---src-pages-zh-blog-js" */),
  "component---src-pages-zh-contact-js": () => import("./../../../src/pages/zh/contact.js" /* webpackChunkName: "component---src-pages-zh-contact-js" */),
  "component---src-pages-zh-index-js": () => import("./../../../src/pages/zh/index.js" /* webpackChunkName: "component---src-pages-zh-index-js" */),
  "component---src-pages-zh-services-js": () => import("./../../../src/pages/zh/services.js" /* webpackChunkName: "component---src-pages-zh-services-js" */),
  "component---src-pages-zh-submitted-js": () => import("./../../../src/pages/zh/submitted.js" /* webpackChunkName: "component---src-pages-zh-submitted-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

